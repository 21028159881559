import React from "react";
import { graphql } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";

import PageWrapper from "../PageWrapper";
import { Section, Title, Text, Box } from "../Core";

import iconQuote from "../../assets/image/png/quote-icon.png";
import Seo from "../Seo/seo";

const Post = styled(Box)`
  overflow: hidden;
  font-size: 1rem;

  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  ul,
  ol {
    margin-bottom: 1.25rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 2.25rem;
    margin-bottom: 1.25rem;
    color: ${({ theme }) => theme.colors.heading} !important;
  }
  ol li {
    list-style-type: decimal;
  }

  ul li {
    list-style-type: disc;
  }

  blockquote {
    margin-bottom: 1.25rem;
    padding-left: 50px;
    position: relative;
    color: ${({ theme }) => theme.colors.text} !important;
    font-size: 20px;
    &::after {
      content: url(${iconQuote});
      display: inline-block;
      min-width: 28px;
      max-width: 28px;
      margin-top: 8px;
      margin-right: 23px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  img,
  iframe,
  video {
    max-width: 100%;
    margin: auto;
    margin-bottom: 2rem;
    display: block;
  }

  p {
    text-align: justify;
  }
`;

const BlogDetails = ({ data }) => {
  const intl = useIntl();
  moment.locale(intl.locale);

  const { title, bodyHtml, author, createdDate } = data.confluencePage;

  return (
    <>
      <PageWrapper footerDark headerDark>
        <Seo
          title={title}
          desc={title}
          article={true}
          node={data.confluencePage}
        />
        <Section className="pb-0 position-relative" bg="black">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="12">
                <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Title color="light" variant="hero">
                    {title}
                  </Title>
                  <Box className="d-flex justify-content-center">
                    <Text color="light" mr={3} opacity={0.7}>
                      {moment(createdDate).format("LL")}
                    </Text>
                    <Text opacity={0.7} color="light">
                      {author.name}
                    </Text>
                  </Box>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="pt-5 pb-0">
          <Container>
            <Row>
              <Col lg="12" className="mb-5">
                <Post dangerouslySetInnerHTML={{ __html: bodyHtml }} />
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default BlogDetails;

export const BlogDetailsQuery = graphql`
  query blogQuery($id: String) {
    confluencePage(id: { eq: $id }) {
      title
      bodyHtml
      confluenceUrl
      summary
      author {
        name
        profilePicture
      }
      metadata {
        labels {
          results {
            label
          }
        }
      }
      createdDate
    }
  }
`;
